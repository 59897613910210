let vw;
let vh;

/***
 *    ##        #######     ###    ########
 *    ##       ##     ##   ## ##   ##     ##
 *    ##       ##     ##  ##   ##  ##     ##
 *    ##       ##     ## ##     ## ##     ##
 *    ##       ##     ## ######### ##     ##
 *    ##       ##     ## ##     ## ##     ##
 *    ########  #######  ##     ## ########
 */
window.addEventListener('load', () => document.documentElement.classList.add('page-loaded'));

/***
*    ########   #######   ######     ########  ########    ###    ########  ##    ##
*    ##     ## ##     ## ##    ##    ##     ## ##         ## ##   ##     ##  ##  ##
*    ##     ## ##     ## ##          ##     ## ##        ##   ##  ##     ##   ####
*    ##     ## ##     ## ##          ########  ######   ##     ## ##     ##    ##
*    ##     ## ##     ## ##          ##   ##   ##       ######### ##     ##    ##
*    ##     ## ##     ## ##    ##    ##    ##  ##       ##     ## ##     ##    ##
*    ########   #######   ######     ##     ## ######## ##     ## ########     ##
*/
document.addEventListener('DOMContentLoaded', () => {
  ff_update_proportional_rem();
  window.addEventListener('resize', ff_update_proportional_rem);
});

/***
*    ##     ## ########  ########     ###    ######## ########    ########  ########   #######  ########         ########  ######## ##     ##
*    ##     ## ##     ## ##     ##   ## ##      ##    ##          ##     ## ##     ## ##     ## ##     ##        ##     ## ##       ###   ###
*    ##     ## ##     ## ##     ##  ##   ##     ##    ##          ##     ## ##     ## ##     ## ##     ##        ##     ## ##       #### ####
*    ##     ## ########  ##     ## ##     ##    ##    ######      ########  ########  ##     ## ########         ########  ######   ## ### ##
*    ##     ## ##        ##     ## #########    ##    ##          ##        ##   ##   ##     ## ##               ##   ##   ##       ##     ##
*    ##     ## ##        ##     ## ##     ##    ##    ##          ##        ##    ##  ##     ## ##        ###    ##    ##  ##       ##     ##
*     #######  ##        ########  ##     ##    ##    ########    ##        ##     ##  #######  ##        ###    ##     ## ######## ##     ##
*/
/**
 * Met à jour le la propriété CSS "font-size" à l'élément <html> calculée en fonction de la largeur de la fenêtre. Ceci nous permet d'utilisé le "rem" de manière proportionnelle.
 */
function ff_update_proportional_rem() {

  vw = window.innerWidth;
  vh = window.innerHeight;

  // ========================================================
  // Ajuste le font-size du HTML pour avoir nos polices proportionnelles
  // ========================================================
  let base;

  // Desktop
  if (vw >= 1200) {
    base = 18;
    rem = vw / (1920 / base);
    if (rem > 22) {
      rem = 22;
    }
    else if (rem < 13) {
      rem = 13;
    }
  }
  // Tablette
  else if (vw < 1200 && vw >= 750) {
    base = 16;
    rem = vw / (1024 / base);
    if (rem < 14) {
      rem = 14;
    }
  }
  // Mobile
  else {
    base = 14;
    rem = vw / (375 / base);
    if (rem < 14) {
      rem = 14;
    }
  }

  // Mise à jour du font-size du HTML et la variable CSS de proportion
  document.documentElement.style.fontSize = `${rem}px`;
  document.documentElement.style.setProperty('--root-font-size-prop', rem / base);

}
